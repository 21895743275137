const Consultation = () => {
  return (
    <div>
      <div className="mt-5 grid gap-10 lg:grid-cols-2 lg:gap-16">
        <div>
          <h1>Ehe- und Lebensberatung</h1>
          <p>
            Das Leben läuft nicht immer so, wie wir es uns erträumt haben. Auch wenn wir im Allgemeinen gut damit
            klarkommen, gibt es Situationen, bei denen ein geschulter Blick von außen hilfreich ist.
          </p>
          <p>
            In meiner Beratung steht das Gespräch im Mittelpunkt, ergänzt durch verschiedene kreative Methoden.
            Grundlegend ist für mich dabei das gegenseitige Vertrauen. Mich selbst sehe ich als Begleiter, der sich
            Ihren Themen und Ihrer Geschwindigkeit anpasst.
          </p>
          <p>
            Gemeinsam begeben wir uns auf die Suche nach unbewussten Zielen, um festgefahrene Verhaltensmuster
            aufzudecken, die hinter belastenden Situationen stehen.
          </p>
          <p>
            Ziel ist es, verschüttete Ressourcen und Stärken zu aktivieren, um so ermutigt Barrieren zu überwinden und
            neue Wege zu gehen.
          </p>
          <p>
            Das christliche Menschenbild ist die Basis meiner Beratung. Zum Einsatz kommen christliche oder biblische
            Elemente und Gebet jedoch immer nur in Absprache mit dem Ratsuchenden. Eine christliche Lebenseinstellung
            ist keine Voraussetzung für die Beratung.
          </p>
        </div>
        <div>
          <h1>Traumazentrierte Fachberatung</h1>
          <p>
            Menschen, die in ihrem Leben Schlimmes erfahren haben, oder die keine Sicherheit in ihrer Kindheit kannten,
            kämpfen häufig auch in ihrem späteren Leben mit den unterschiedlichsten Schwierigkeiten.
          </p>
          <p>
            Hohe Funktionalität, großer innerer Stress, ein Gefühl des Ausgeliefertseins und der Hilflosigkeit, des
            Misstrauens anderen Menschen gegenüber und der großen Sehnsucht nach gelingender Beziehung, der inneren
            Zerrissenheit und der ambivalentesten überflutenden Gefühle, sind Alltag.
          </p>
          <p>
            Oft wurden die unterschiedlichsten Versuche unternommen, um Hilfe zu bekommen, aber der Erfolg stellte sich
            nicht ein. Alte Verhaltensmuster ändern sich nicht.
          </p>
          <p>
            Im Rahmen der Traumabegleitung können Strategien für den Alltag entwickelt werden, um eine neue Stabilität
            zu erreichen. Der innere Stress wird vermindert und es ist möglich, die eigenen Verhaltensweisen zu
            verstehen, neu zu bewerten und gegebenenfalls zu verändern. So kann Ruhe ins Innere kommen, Versöhnung mit
            sich selbst und anderen stattfinden und ein anderes Erleben und Reagieren wird möglich.
          </p>
        </div>
      </div>
      <div className="col-span-2 text-center italic">
        Selbstverständlich werden alle Gesprächsinhalte von mir vertraulich behandelt.
      </div>
    </div>
  );
};

export default Consultation;
