import { initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAkbFToHy4xDCmS_6QUbDeTTGgNvZYDo4Y",
  authDomain: "beratung-klotz-brenner.firebaseapp.com",
  projectId: "beratung-klotz-brenner",
  storageBucket: "beratung-klotz-brenner.appspot.com",
  messagingSenderId: "874175002852",
  appId: "1:874175002852:web:ffa774334258133f7b54f1",
};

export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app, "europe-west3");

export const sendMail = httpsCallable(functions, "sendMail");

const useEmulators = process.env.REACT_APP_USE_FIREBASE_EMUlATORS === "true";
if (useEmulators) connectFunctionsEmulator(functions, window.location.hostname, 5001);
