const Welcome = () => {
  return (
    <div>
      <h1 className="font-normal text-[#218c74]">Herzlich Willkommen</h1>
      <div className="flex flex-col items-center gap-5 lg:flex-row-reverse lg:items-stretch lg:gap-12">
        <div className="min-w-fit text-right">
          <img
            src="profile-1024.jpg"
            alt="Doris Klotz-Brenner"
            className="w-[400px] border border-stone-600"
            width={4}
            height={3}
            sizes="(min-width: 464px) 400px, calc(100vw - 64px)"
            srcSet="profile-1024.webp 1024w, profile-768.webp 768w, profile-512.webp 512w"
          />
        </div>
        <div>
          <p>
            Als Traumazentrierte Fachberaterin, Individualpsychologische Beraterin und Heilpraktikerin begrenzt auf das
            Gebiet der Psychotherapie begleite und unterstütze ich Menschen in Krisen und Herausforderungen.
          </p>
          <p>Ein Schwerpunkt meiner Arbeit liegt in der Begleitung von Menschen mit traumatischem Hintergrund.</p>
          <p>
            Darüber hinaus sind Ehe- und Arbeitsthemen häufiger Bestandteil der Beratung. Man könnte auch sagen, alles,
            was das Leben ausmacht.
          </p>
          <p>Ich bin verheiratet, habe vier erwachsene Kinder und wurde 1966 im schönen Badnerland geboren.</p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
