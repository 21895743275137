import { Compass, PersonCircle } from "styled-icons/bootstrap";
import { Presenter } from "styled-icons/fluentui-system-filled";

export const ConsultationSpacer = () => {
  return (
    <div className="flex aspect-[7/1] w-full items-end justify-center bg-[url('./img/wave-white-emerald100.svg')] bg-cover bg-center bg-no-repeat">
      <Compass id="consultation" className="w-32 translate-y-24 text-emerald-600" />
    </div>
  );
};

export const QualificationsSpacer = () => {
  return (
    <div className="flex aspect-[7/1] w-full items-end justify-center bg-[url('./img/wave-emerald100-300.svg')] bg-cover bg-center bg-no-repeat">
      <PersonCircle id="qualifications" className="w-32 translate-y-24 text-emerald-600" />
    </div>
  );
};

export const BackgroundSpacer = () => {
  return (
    <div className="flex aspect-[7/1] w-full items-end justify-center bg-[url('./img/wave-emerald300-500.svg')] bg-cover bg-center bg-no-repeat">
      <Presenter id="speaker" className="w-32 translate-y-24 text-emerald-800" />
    </div>
  );
};
