const Speaker = () => {
  return (
    <>
      <h1>Unterwegs als Referentin</h1>
      <img
        src="speaker-1024.jpg"
        alt="Doris Klotz-Brenner as speaker."
        className="mx-auto mb-5 w-[512px] border border-emerald-600 lg:float-left lg:mb-0 lg:mr-6 lg:ml-0"
        width={128}
        height={73}
        sizes="(min-width: 576px) 512px, calc(100vw - 64px)"
        srcSet="speaker-1024.webp 1024w, speaker-768.webp 768w, speaker-512.webp 512w"
      />
      <p>
        Zu meinen Herzensanliegen gehört es, meine Zuhörer zu ermutigen und auch schwierige Themen mit einem Schuss
        Humor zu würzen. So können auch ernste Dinge in Leichtigkeit eingebettet und trotzdem auf den Punkt gebracht
        werden.
      </p>
      <p>
        Ob es sich dabei um psychologisches, traumatisches, christliches oder das ganz normale Lebenschaos handelt. Ich
        versuche, immer den Bezug zum Alltag, meinem Leben als Therapeut, Mutter, Witwe, Ehefrau und Glaubende
        herzustellen.
      </p>
      <p>
        Gelegenheiten boten sich seither bei Frauenfrühstücken, Candlelight-Dinner, Predigt-Gespräch, Gebetsschulen,
        Gebetshaus und in verschiedenen Ausbildungen (ICL, ICTB, Trauer-/Hospizbegleiter).
      </p>
      <div className="text-center italic">Fragen Sie mich gerne zu den unterschiedlichsten Themen an.</div>
    </>
  );
};

export default Speaker;
