import References from "./References";

const CV = [
  [1990, <span>Diplom - Ingenieurin (FH) für Chemische Technik, Mannheim</span>],
  [
    2016,
    <span>
      Individualpsychologische Lebensberaterin und Therapeutische Seelsorgerin (
      <a href="https://www.icl-institut.org" className="link">
        ICL
      </a>
      )
    </span>,
  ],
  [
    2017,
    <span>
      Mitgliedschaft ACC (
      <a href="https://www.acc-deutschland.org" className="link">
        Association of Christian Counselors
      </a>
      )
    </span>,
  ],
  [
    2017,
    <span>
      Kriseninterventionsberaterin bei der{" "}
      <a href="https://www.lebenswert-nu.de" className="link">
        Beratungsstelle Lebenswert
      </a>
    </span>,
  ],
  [
    2020,
    <span>
      Traumazentrierte Fachberaterin (
      <a href="https://www.ictb-institut.de" className="link">
        ICTB
      </a>
      )
    </span>,
  ],
  [
    2020,
    <span>
      Mitgliedschaft NCTB (
      <a href="https://www.nestli-seminare.de/netzwerk/infos-zum-nctb" className="link">
        Netzwerk für christlich orientierte Traumabegleiter
      </a>
      )
    </span>,
  ],
  [
    2021,
    <span>
      Heilpraktikerin begrenzt auf das Gebiet der Psychotherapie (HPG), geprüft durch das Gesundheitsamt Augsburg
    </span>,
  ],
  [
    2022,
    <span>
      Fortbildung im Traumahilfezentrum München: Diagnose und Behandlung der chronischen traumabezogenen Dissoziationen
      der Persönlichkeit bei{" "}
      <a href="https://www.dissociativedisorder.org/wilkommen" className="link">
        Ellert R.S. Nijenhuis,
      </a>{" "}
      PhD , Niederlande, Basiskurs
    </span>,
  ],
  [2022, <span>Dozentin beim Institut für christlich orientierte Traumabegleitung (ICTB)</span>],
].reverse();

const Qualifications = () => {
  return (
    <div>
      <h1>Qualifikationen</h1>
      <div className="mb-3 flex flex-col items-center gap-5 lg:flex-row-reverse lg:items-stretch lg:gap-16">
        <div className="mx-auto flex min-w-fit flex-col items-center">
          <img
            src="office-768.jpg"
            alt="Doris Klotz-Brenner"
            className="w-[440px] border border-emerald-800"
            width={3}
            height={4}
            sizes="(min-width: 504px) 440px, calc(100vw - 64px)"
            srcSet="office-1024.webp 1024w, office-768.webp 768w, office-672.webp 672w, office-576.webp 576w, office-384.webp 384w"
          />
        </div>
        <ul className="list-disc text-left">
          {CV.map(([year, description], index) => (
            <li key={index} className="mb-2">
              <span className="font-bold">{year}: </span>
              <span>{description}</span>
            </li>
          ))}
        </ul>
      </div>
      <img src="acc.png" alt="acc-badge" className="mx-auto my-3 w-[230px]" width={10} height={7} srcSet="acc.webp" />
      <div className="text-center italic">
        Durch regelmäßige Supervisionen und Intervisionen findet eine ständige Qualitätssicherung statt.
      </div>
      <div className="mt-8">
        <References />
      </div>
    </div>
  );
};

export default Qualifications;
