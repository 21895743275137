import { useEffect } from "react";
import { Link } from "react-router-dom";

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="text-lg text-stone-900">
      <div className="sticky top-0 z-20 bg-orange-400 shadow-md">
        <div className="container flex h-16 items-center">
          <Link to="/" className="cursor-pointer">
            <img src="logo-700.svg" alt="Beratung Klotz-Brenner" width={282.3} height={32.08} />
          </Link>
        </div>
      </div>
      <div className="container my-6">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Doris Klotz-Brenner
          <br />
          Heilpraktikerin f&uuml;r Psychotherapie
          <br />
          Hafnerweg 1<br />
          89231 Neu-Ulm
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: +49 151 51936485
          <br />
          E-Mail: beratung.klotz.brenner@gmx.net
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
          <br />
          82154637390
        </p>

        <h2>Aufsichtsbeh&ouml;rde</h2>
        <p>
          Gesundheitsamt Neu-Ulm
          <br />
          Landkreis Neu-Ulm
          <br />
          Kantstra&szlig;e 8<br />
          89231 Neu-Ulm
        </p>
        <p>
          <a
            href="http://www.landkreis-nu.de/oeffentlicher-Gesundheitsdienst"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.landkreis-nu.de/oeffentlicher-Gesundheitsdienst
          </a>
        </p>

        <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
        <p>
          Berufsbezeichnung:
          <br />
          Heilpraktikerin f&uuml;r den Bereich Psychotherapie
        </p>
        <p>Es gelten folgende berufsrechtliche Regelungen:</p>
        <p>
          Heilpraktikergesetz
          <br />
          einsehbar unter:{" "}
          <a
            href="https://www.gesetze-im-internet.de/heilprg/BJNR002510939.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.gesetze-im-internet.de/heilprg/BJNR002510939.html
          </a>
        </p>
        <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
        <p>
          <strong>Name und Sitz des Versicherers:</strong>
          <br />
          Axa Versicherung AG
          <br />
          Postfach 920145
          <br />
          51151 K&ouml;ln
        </p>
        <p>
          <strong>Geltungsraum der Versicherung:</strong>
          <br />
          Deutschland
        </p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </p>

        <p className="italic">
          Quelle:{" "}
          <a href="https://www.e-recht24.de" className="link">
            https://www.e-recht24.de
          </a>
        </p>
      </div>
    </div>
  );
};

export default Impressum;
