const MessageSend = ({ close }: { close: () => void }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-stone-300/40 backdrop-blur-sm">
      <div className="mx-auto mt-16 flex w-fit flex-col items-center rounded-lg border border-stone-600 bg-cyan-400 px-8 py-4 text-stone-700">
        <h3 className="text-center">Ihre Nachricht wurde versandt</h3>
        <button
          className="focus:ring-sky focus:border-sky mx-auto w-32 rounded-lg border border-stone-600 bg-stone-100 py-2 outline-0 hover:bg-stone-200 focus:ring-2"
          onClick={close}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default MessageSend;
