import { Link } from "react-router-dom";
import { Mailbox } from "styled-icons/bootstrap";
import Consultation from "./Consultation";
import Contact from "./Contact";
import Header from "./Header";
import Qualifications from "./Qualifications";
import { BackgroundSpacer, ConsultationSpacer, QualificationsSpacer } from "./Spacer";
import Speaker from "./Speaker";
import Welcome from "./Welcome";

const Root = () => {
  return (
    <div className="md:text-justfy relative font-sans text-lg font-normal text-stone-900">
      <Header />
      <div className="bg-white pt-10" id="welcome">
        <div className="container">
          <Welcome />
        </div>
      </div>
      <ConsultationSpacer />
      <div className="bg-emerald-100 pt-28">
        <div className="container">
          <Consultation />
        </div>
      </div>
      <QualificationsSpacer />
      <div className="bg-emerald-300 pt-28 pb-10">
        <div className="container mx-auto">
          <Qualifications />
        </div>
      </div>
      <BackgroundSpacer />
      <div className="bg-emerald-500 pt-28 pb-10">
        <div className="container">
          <Speaker />
        </div>
      </div>
      <div className="border-t border-orange-500 bg-orange-400 pt-8 pb-2 shadow-[0_0_5px_rgba(0,0,0,0.05)]">
        <div className="flex justify-center">
          <Mailbox id="contact" className="w-32 text-orange-600" />
        </div>
        <div className="container">
          <Contact />
        </div>
      </div>
      <div className="flex justify-center gap-3 bg-orange-400 py-2">
        <Link to="/impressum" className="link">
          Impressum
        </Link>
        <Link to="/privacy" className="link">
          Datenschutzerklärung
        </Link>
      </div>
    </div>
  );
};

export default Root;
