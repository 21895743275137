import { MenuIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { animateScroll, Link } from "react-scroll";
import { Transition } from "react-transition-group";

function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error(`Node expected`);
  }
}

const scrollTo = [
  { to: "welcome", name: "Willkommen" },
  { to: "consultation", name: "Beratung" },
  { to: "qualifications", name: "Qualifikationen" },
  { to: "speaker", name: "Referentin" },
  { to: "contact", name: "Kontakt" },
];

const Header = () => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);
  const handleClick = (event: Event) => {
    assertIsNode(event.target);
    if (menuRef.current && !menuRef.current.contains(event.target)) setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    window.addEventListener("scroll", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("scroll", handleClick);
    };
  }, []);

  return (
    <div className="sticky top-0 z-20">
      <div className="bg-orange-400 shadow-md">
        <nav className="container flex h-16 items-center justify-between gap-6">
          <img
            onClick={() => animateScroll.scrollToTop({ duration: 300 })}
            className="min-h-0 min-w-0 shrink cursor-pointer"
            src="logo-700.svg"
            width={282.3}
            height={32.08}
            alt="Beratung Klotz-Brenner"
          />
          <div className="hidden gap-4 lg:ml-auto lg:flex">
            {scrollTo.map(({ to, name }) => (
              <Link key={to} to={to} smooth offset={-90} duration={300} className="cursor-pointer" href="/">
                {name}
              </Link>
            ))}
          </div>
          <div
            className={"w-10 shrink-0 lg:hidden"}
            role="button"
            aria-label="Toggle Navigation"
            onClick={() => setOpen(!open)}
            ref={menuRef}
          >
            <MenuIcon className="" />
          </div>
        </nav>
      </div>
      <Transition in={open} timeout={100} mountOnEnter={true} unmountOnExit={true} nodeRef={nodeRef}>
        {(state) => (
          <div
            ref={nodeRef}
            className={classNames(
              "absolute w-full backdrop-blur-sm bg-orange-400/80 border-b-2 border-orange-400 shadow-md",
              "transition duration-100",
              state === "entering" || state === "entered" ? "opacity-100" : "opacity-0"
            )}
          >
            <div className="container flex flex-col gap-1 py-4">
              {scrollTo.map(({ to, name }) => (
                <Link key={to} to={to} smooth offset={-90} duration={300} href="/">
                  {name}
                </Link>
              ))}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default Header;
