import { useEffect, useState } from "react";
import { QuoteAltLeft, QuoteAltRight } from "styled-icons/boxicons-solid";
import { Circle } from "styled-icons/fa-solid";

const references = [
  {
    text: "Ich habe lange nach einer Person gesucht, die mit mir gemeinsam die Stolpersteine auf meinem Lebensweg betrachtet und Lösungsansätze herausarbeitet. In Doris Klotz-Brenner habe ich eine solche Wegbegleiterin gefunden! Durch ihre sehr professionelle, methodenreiche, einfühlsame und klare Herangehensweise kann ich mit schweren Tagen und Situationen in meinem Leben viel besser umgehen. Ich bin ihr sehr dankbar.",
    from: "Pia S.",
  },
  {
    text: "Ich habe nie wirklich gelernt, Gefühle zu äußern - geschweige denn sie wahrzunehmen. Durch die einfühlsamen Gespräche mit Doris ist mir das überhaupt erst bewusst geworden und so konnte ich nicht nur die persönliche Krisenzeit hinter mir lassen, sondern auch ganz neue Seiten an mir kennen lernen! Das war so heilsam und befreiend! Ich fühle mich von Doris sehr verstanden und supergut bei ihr aufgehoben. Ich freue mich auf das nächste Gespräch!",
    from: "Dirk S.",
  },
  {
    text: "Doris begleitete mich über mehrere Jahre hinsichtlich meiner traumatischen Erlebnisse aus der Kindheit. Dank ihrer Begleitung wurden in den Gesprächen die belastenden frühkindlichen Erlebnisse aufgedeckt und konnten bearbeitet und verarbeitet werden. Jedes Gespräch mit Doris offenbarte mir bisher Erkenntnisse über mich selbst und hat mich Schritt für Schritt weitergebracht. Die Gespräche mit Doris waren verständnisvoll, fachlich kompetent und ich fühlte mich bei Ihr als Therapeutin an der richtigen Stelle. ",
    from: "Achim B.",
  },
  {
    text: "Die zwischenmenschliche Ebene passt sehr gut, Frau Klotz-Brenner bildet sich scheinbar ohne Unterbrechung ständig weiter und sie beeindruckt mich immer wieder durch ihr kluges Hinterfragen. Sie hat einen tollen Humor! Bei mir hat sich Vieles zum Positiven verändert, so sagte mein Mann beispielsweise sinngemäß, seit ich zu ihr ginge, sei unsere Ehe konfliktärmer geworden. Auch habe ich kaum noch Alpträume, die mir mein Leben lang ständige Begleiter waren - sehr angenehm!",
    from: "Kerstin M.-Th.",
  },
];
const longestIndex = 2;

const References = () => {
  const [showIndex, setShowIndex] = useState(0);
  const [runningTimeout, setRunningTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(runningTimeout);
    setRunningTimeout(setTimeout(() => setShowIndex((showIndex + 1) % references.length), 25000));
  }, [showIndex, runningTimeout]);

  return (
    <div className="flex flex-col-reverse text-stone-700">
      <div className="flex justify-center gap-3">
        {references.map((_, index) => (
          <div
            key={index}
            className={"w-4 transition duration-300" + (index === showIndex ? " opacity-100 delay-150" : " opacity-50")}
          >
            <Circle
              onClick={() => {
                setShowIndex(index);
              }}
              className="cursor-pointer"
            />
          </div>
        ))}
      </div>
      <div className="relative">
        {references.map(({ text, from }, index) => (
          <div
            key={index}
            className={
              "flex flex-col container px-0 sm:px-2 font-serif transition duration-300" +
              (index !== longestIndex ? " absolute top-0" : "") +
              (index === showIndex ? " opacity-100 delay-150 z-10" : " opacity-0 select-none")
            }
          >
            <QuoteAltLeft className="w-5" />
            <div className="container relative px-2 sm:px-8">
              <span className="m-0 p-0 italic">{text}</span>
              <span className="inline-block w-48" />
              <span className="absolute right-8 inline-block font-sans">
                <span className="[letter-spacing:_-1px;]">-----------</span>
                <span className="inline-block w-3" />
                {from}
              </span>
            </div>
            <QuoteAltRight className="w-5 place-self-end" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default References;
